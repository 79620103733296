import { MinusSquareFilled, SettingOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
import { useEffect, useState } from 'react'

import { Button, SearchBar } from 'ui'

import { StatusFilter } from '../_siteWide/statusFilter/statusFilter'
import { GetProviders } from '../../api/eligibility/eligibilityApi'
import { useGlobalContext } from '../../context/GlobalContextProvider'
import api from '../../utilities/api'
import EligibilityResponseModal from './modals/EligibilityResponseModal/EligibilityResponseModal'
import EligibilitySettingsModal from './modals/EligibilitySettingsModal/EligibilitySettingsModal'
import OneTimeRequestModal from './modals/OneTimeRequestModal/OneTimeRequestModal'
import PatientUpdateFormModal from './modals/PatientUpdateFormModal/PatientUpdateFormModal'
import PatientUpdateResponseModal from './PatientEligibilityDetail/modals/PatientUpdateResponseModal'
import PatientEligibilityDetail from './PatientEligibilityDetail/PatientEligibilityDetail'
import EligibilityContextProvider, {
  useEligibilityContext,
} from './shared/context/EligibilityContext'
import PatientEligibilityDetailContextProvider, {
  usePatientEligibilityDetailContext,
} from './shared/context/PatientEligibilityDetailContext'
import { EligibilityHistory } from './shared/utilities/eligibilityTyping'
import Appointments from './tabs/Appointments/AppointmentsGrid'
import OneTimeHistory from './tabs/OneTimeRequestsGrid'
import Patients from './tabs/PatientsGrid'

import './Eligibility.scss'

import { observer } from '@legendapp/state/react'

import {
  eligibilityFilters,
  QuickFilters,
} from 'trellis:constants/statusFilters'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

interface GetPatientResponse {
  CarrierId: string
  CarrierName: string
  CustomerId: number
  GroupNumber: string
  IndividualNpi: string
  PatientId: string
  PatientFirstName: string
  PatientBirthdate: string
  PatientGender: string
  PatientIsSub: boolean
  PatientLastName: string
  PatientMiddleName: string
  PatientSuffix: string
  ProviderFirstName: string
  ProviderLastName: string
  Status: string
  SubscriberBirthdate: string
  SubscriberFirstName: string
  SubscriberGender: string
  SubscriberId: string
  SubscriberLastName: string
  SubscriberMiddleName: string
  SubscriberSuffix: string
  SyncId: string
  VerificationHistory: EligibilityHistory[]
}

interface NewPatient extends GetPatientResponse {
  TaxonomyCode: string
}

const Eligibility = observer(() => {
  const flags = LDFlags$.get()

  const authentication = GlobalState.Auth.get()
  const { practiceDetails } = useGlobalContext()
  const {
    activeTab,
    appointmentState,
    loading,
    oneTimeRequestState,
    patientState,
    setActiveTab,
    setAppointmentState,
    setOneTimeRequestState,
    setParticipatingCarriers,
    setPatientState,
    setProviders,
    setShowAppointmentsSummary,
    setShowEligibilityResponseModal,
    setShowPatientUpdateFormModal,
    showAppointmentsSummary,
    showEligibilityResponseModal,
    showPatientUpdateFormModal,
    verificationResponse,
  } = useEligibilityContext()
  const {
    setPatient,
    setShowingPatientResponseModal,
    setShowPatientDetail,
    showingPatientResponseModal,
    showPatientDetail,
  } = usePatientEligibilityDetailContext()

  const [showOneTimeRequestModal, setShowOneTimeRequestModal] =
    useState<boolean>(false)
  const [showEligibilitySettingsModal, setShowEligibilitySettingsModal] =
    useState<boolean>(false)

  useEffect(() => {
    // NOTE: hashchange listener is for updating sidebar nav active ui and may not be needed in spa with react routing
    window.addEventListener('hashchange', () => getActiveTab())

    return () => window.removeEventListener('hashchange', getActiveTab)
  }, [])

  useEffect(() => {
    if (authentication?.AccessToken) {
      getParticipatingCarriers()
      if (authentication.CustomerId) getProviders()
    }
  }, [authentication?.AccessToken, authentication?.CustomerId])

  // NOTE: getActiveTab is for updating sidebar nav active ui and may not be needed in spa with react routing
  const getActiveTab = () => {
    const locationHash =
      flags.eligibilityUsevynedentalsync ||
      flags.operaVynesyncAutomatedeligibility
        ? '#appointments'
        : '#patients'

    window.location.hash
      ? setActiveTab(window.location.hash.substring(1))
      : (window.location.hash = locationHash)
  }

  const getParticipatingCarriers = async () => {
    await api.getParticipatingCarriers().then(({ data }) => {
      setParticipatingCarriers(data.Carriers)
    })
  }

  const getProviders = async () => {
    if (
      flags.eligibilityUsevynedentalsync ||
      flags.operaVynesyncAutomatedeligibility
    ) {
      await GetProviders().then(({ data }) => setProviders(data))
    }
  }

  // NOTE: this hash logic is for updating sidebar nav active ui and may not be needed in spa with react routing
  const handleTabChange = (tab: string) => {
    window.location.hash = `#${tab}`
    setActiveTab(tab)
  }

  const HeaderRow = () => {
    const addPatient = async () => {
      const newPatient: NewPatient = {
        CarrierId: null,
        CarrierName: null,
        CustomerId: authentication?.CustomerId,
        GroupNumber: '',
        IndividualNpi: practiceDetails?.providerNPI,
        PatientId: '0',
        PatientFirstName: '',
        PatientBirthdate: '',
        PatientGender: '',
        PatientIsSub: false,
        PatientLastName: '',
        PatientMiddleName: '',
        PatientSuffix: '',
        ProviderFirstName: practiceDetails?.providerFirstName,
        ProviderLastName: practiceDetails?.providerLastName,
        Status: '',
        SubscriberBirthdate: '',
        SubscriberFirstName: '',
        SubscriberGender: '',
        SubscriberId: null,
        SubscriberLastName: '',
        SubscriberMiddleName: '',
        SubscriberSuffix: '',
        SyncId: null,
        TaxonomyCode: practiceDetails?.taxonomyCode,
        VerificationHistory: [],
      }
      setPatient(newPatient)
      setShowPatientDetail(true)
    }

    return (
      <section className='page-content-header__container'>
        <div className='flex justify-sb items-center'>
          <h1 className='page-content-header__title'>Eligibility & Benefits</h1>
          {activeTab === 'appointments' && (
            <p
              className='text-link ml-100'
              onClick={() =>
                setShowAppointmentsSummary(!showAppointmentsSummary)
              }
            >
              <MinusSquareFilled className='mr-050' />
              {showAppointmentsSummary ? 'Hide Summary' : 'Show Summary'}
            </p>
          )}
        </div>
        <Button
          label={
            flags.eligibilityUsevynedentalsync ||
            flags.operaVynesyncAutomatedeligibility
              ? 'One-time Request'
              : 'Add Patient'
          }
          onClick={() =>
            flags.eligibilityUsevynedentalsync ||
            flags.operaVynesyncAutomatedeligibility
              ? setShowOneTimeRequestModal(true)
              : addPatient()
          }
          size='large'
          type='primary'
        />
      </section>
    )
  }

  const hasAutomatedEligibility =
    flags.eligibilityUsevynedentalsync ||
    flags.operaVynesyncAutomatedeligibility

  const tabItems = [
    !hasAutomatedEligibility && {
      key: 'patients',
      label: 'Patient List',
    },
    hasAutomatedEligibility && {
      disabled: loading,
      key: 'appointments',
      label: 'Appointments',
    },
    hasAutomatedEligibility && {
      disabled: loading,
      key: 'requests',
      label: 'One-time History',
    },
  ]

  const toolbar: JSX.Element = (
    <section className='eligibility-toolbar flex justify-between items-center gap-250'>
      <div className='flex items-center'>
        <Tabs
          activeKey={activeTab}
          items={tabItems}
          onChange={(key: string) => handleTabChange(key)}
          style={{ width: hasAutomatedEligibility ? '225px' : '75px' }}
        />
        <StatusFilter
          filterState={
            activeTab === 'patients' ? patientState : appointmentState
          }
          setFilterState={
            activeTab === 'patients' ? setPatientState : setAppointmentState
          }
          loading={loading}
          quickFilters={
            activeTab === 'patients'
              ? [QuickFilters.NOT_ELIGIBLE, QuickFilters.ELIGIBLE]
              : null
          }
          filterOptions={
            activeTab === 'patients'
              ? eligibilityFilters
              : activeTab === 'appointments' &&
                  flags.operaVynesyncAutomatedeligibility
                ? eligibilityFilters
                : []
          }
          hideTags
        />
      </div>
      <div className='flex items-center gap-125'>
        <div>
          {!flags.eligibilityUsevynedentalsync &&
            !flags.operaVynesyncAutomatedeligibility && (
              <SearchBar
                placeholder='Search by patient name'
                state={patientState}
                setState={setPatientState}
              />
            )}
          {(flags.eligibilityUsevynedentalsync ||
            flags.operaVynesyncAutomatedeligibility) &&
            activeTab === 'appointments' && (
              <SearchBar
                placeholder='Search by patient name'
                state={appointmentState}
                setState={setAppointmentState}
              />
            )}
          {(flags.eligibilityUsevynedentalsync ||
            flags.operaVynesyncAutomatedeligibility) &&
            activeTab === 'requests' && (
              <SearchBar
                placeholder='Search by patient name'
                state={oneTimeRequestState}
                setState={setOneTimeRequestState}
              />
            )}
        </div>
        {flags.trellisEligibilitySettingsFeature &&
          flags.operaVynesyncAutomatedeligibility && (
            <SettingOutlined
              className='fs-125'
              onClick={() => setShowEligibilitySettingsModal(true)}
              style={{ color: 'var(--charcoal-200, #87878a)' }}
            />
          )}
      </div>
    </section>
  )

  const grids = (
    <>
      {activeTab === 'patients' && !flags.eligibilityUsevynedentalsync && (
        <Patients toolbar={toolbar} />
      )}
      {activeTab === 'appointments' && <Appointments toolbar={toolbar} />}
      {activeTab === 'requests' && <OneTimeHistory toolbar={toolbar} />}
    </>
  )

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      {!showPatientDetail ? (
        <>
          <HeaderRow />
          <section>{authentication?.AccessToken && grids}</section>
        </>
      ) : (
        <PatientEligibilityDetail />
      )}
      {verificationResponse && showEligibilityResponseModal && (
        <EligibilityResponseModal
          isOpen={showEligibilityResponseModal}
          setIsOpen={setShowEligibilityResponseModal}
        />
      )}
      <OneTimeRequestModal
        isVisible={showOneTimeRequestModal}
        setIsVisible={setShowOneTimeRequestModal}
      />
      <PatientUpdateFormModal
        isOpen={showPatientUpdateFormModal}
        setIsOpen={setShowPatientUpdateFormModal}
      />
      <PatientUpdateResponseModal
        isOpen={showingPatientResponseModal}
        setIsOpen={setShowingPatientResponseModal}
      />
      <EligibilitySettingsModal
        isOpen={showEligibilitySettingsModal}
        setIsOpen={setShowEligibilitySettingsModal}
      />
    </article>
  )
})

export default () => (
  <EligibilityContextProvider>
    <PatientEligibilityDetailContextProvider>
      <Eligibility />
    </PatientEligibilityDetailContextProvider>
  </EligibilityContextProvider>
)
